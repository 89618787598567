import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {HemnetComponent} from './hemnet/hemnet.component'

export const routes: Routes = [
  {
    path: 'hemnet',
    component: HemnetComponent
  },
  {
    path: '',
    redirectTo: 'hemnet',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
