<spb-header class="header"></spb-header>
<spb-breadcrumbs [links]="['https://www.sparbankensyd.se/lana-pengar/lanelofte/']"
                 path="Låna | Räkna på ditt boende">>
</spb-breadcrumbs>
<div class="spb-holder mat-typography">
  <div class="title">
    <h1>Räkna på ditt boende</h1>
  </div>
  <spb-object-info class="object-info"></spb-object-info>
  <div class="calc-holder">
    <spb-input></spb-input>
    <spb-calculation></spb-calculation>
  </div>
  <div class="button-holder">
    <div class="button">
      <a [href]="'https://www.sparbankensyd.se/lana-pengar/lanelofte/' + utmTags">Ansök om lånelöfte</a></div>
    <div class="button">
      <a [href]="'https://www.sparbankensyd.se/lana-pengar/bolan/' + utmTags">Ansök om bolån</a>
    </div>
  </div>
  <div class="disclaimer">
    Vid en rörlig (3 mån) ränta på 1,67% (ränta per 8 februari 2022) blir den effektiva räntan 1,68 % för ett lån på 1
    000 000 kr med återbetalningstid på 40 år. Totalt att betala på 40 år blir 1 334 050 kr (varav ränta 334 050 kr).
    Din kostnad för lånets första månad blir 3 500 kr. Du amorterar lika mycket varje månad och om inte räntan ändras
    under hela lånets löptid innebär det att din totala månadskostnad kommer att sjunka då lånebeloppet minskar när du
    amorterar. Du kommer att ha 480 st betalningstillfällen. Vi har ingen uppläggningsavgift och ingen aviavgift. Vid
    flytt av bundna bolån kan din nuvarande bank ta ut ränteskillnadsersättning.
  </div>
</div>
<div class="spb-filler"></div>
<spb-footer></spb-footer>
