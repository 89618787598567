import {Component, OnDestroy, OnInit} from '@angular/core'
import {HemnetService, IHemnetData} from '../../services/hemnet.service'
import {Subscription} from 'rxjs'

@Component({
  selector: 'spb-object-info',
  templateUrl: './object-info.component.html',
  styleUrls: ['./object-info.component.scss']
})
export class ObjectInfoComponent implements OnInit, OnDestroy {

  public imageUrl: string = 'assets/no-thumb.png'

  public name: string = 'Ingen adress 1a'

  public askingPrice: number = 0

  public area: number = 0

  public year: number = 2000

  public fee: number = undefined

  private hemnetOutput$: Subscription

  constructor(
    private hemnetService: HemnetService,
  ) {
  }

  ngOnInit(): void {
    this.hemnetOutput$ = this.hemnetService.hemnetOutput.subscribe({
      next: (hemnetData: IHemnetData) => {
        this.imageUrl = hemnetData.image || 'assets/no-thumb.svg'
        this.year = hemnetData.year
        this.area = hemnetData.area
        this.fee = hemnetData.fee
        this.askingPrice = hemnetData.askingPrice
        this.name = hemnetData.name
      }
    })
  }

  ngOnDestroy(): void {
    this.hemnetOutput$.unsubscribe()
  }
}
