<form [formGroup]="inputForm" class="holder">

  <div class="input">
    <label for="expectedPrice">Vad tror du slutpriset blir?</label>
    <div class="input-container" data-after-content="kr">
      <input (input)="removeNonDigits($event)" formControlName="expectedPrice" id="expectedPrice" inputmode="numeric">
    </div>
  </div>

  <div class="input">
    <label for="downPayment">Kontantinsats</label>
    <div class="input-container" data-after-content="kr">
      <input (input)="removeNonDigits($event)"
             [ngClass]="{'error': downPaymentError}"
             formControlName="downPayment"
             id="downPayment"
             inputmode="numeric">
    </div>
    <p [ngClass]="{'error': downPaymentError}">Kontantinsatsen är minst 15% ({{calculatedDownPayment}}) av bostadens
      pris</p>
  </div>

  <div class="input">
    <label for="interestRate">Ränta</label>
    <div class="input-container" data-after-content="%">
      <input formControlName="interestRate" inputmode="numeric"
             id="interestRate">
    </div>
    <p>Listränta för lån bundet på 3 månader</p>
  </div>

  <div class="input">
    <label for="fee">Månadsavgift</label>
    <div class="input-container" data-after-content="kr">
      <input (input)="removeNonDigits($event)" formControlName="fee" id="fee" inputmode="numeric">
    </div>
  </div>

  <div class="input">
    <label for="runCost">Driftskostnad per år</label>
    <div class="input-container" data-after-content="kr">
      <input (input)="removeNonDigits($event)" formControlName="runCost" id="runCost" inputmode="numeric">
    </div>
  </div>
</form>
