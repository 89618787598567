import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {FooterComponent} from './footer/footer.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {BackdropComponent} from './backdrop/backdrop.component'


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BackdropComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    BackdropComponent,
  ]
})
export class HemnetCommonModule {
}
