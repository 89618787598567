<div class="contact-holder">
  <div class="contact">
    <div class="logo">
      <div class="spb-logo"><a href="https://sparbankensyd.se">Sparbanken Syd</a></div>
    </div>
    <div class="contact-button">
      <a href="https://www.sparbankensyd.se/kontakt">Kontakta oss</a>
    </div>
  </div>
</div>
<div class="bottom-foot">
  <div>All Rights Reserved Sparbanken Syd ©&nbsp;2020 - 2022</div>
</div>
