import {Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit, Output} from '@angular/core'
import {Router} from '@angular/router'
import {WINDOW} from '../../application/window.provider'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() application = new EventEmitter()

  /**
   * The constructor
   *
   * @param router - Neeeded to navigate on menu actions from code.
   * @param ngZone - The zone is needed for safe navigation.
   * @param injectedWindow - If we navigate of app we need this.
   */
  constructor(
    private router: Router,
    private ngZone: NgZone,
    @Inject(WINDOW) private injectedWindow: Window,
  ) {
  }

  /**
   * Start subscribing to the admin state.
   */
  ngOnInit() {
  }

  /**
   * Remember to unsubscribe to avoid leakage.
   */
  ngOnDestroy() {
  }

  /**
   * A navigation function that can navigate internally or externally
   *
   * @param link - The place to go, start with https:// to navigate externally.
   */
  public navigate(link: string): void | any {
    if (link.indexOf('https://') !== -1) {
      this.injectedWindow.location.href = link
    } else {
      return this.ngZone.run(() => this.router.navigate([link]))
    }
  }
}
