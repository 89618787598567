import {Component, OnInit} from '@angular/core'
import {HemnetService} from '../services/hemnet.service'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {environment} from '../../environments/environment'

@Component({
  selector: 'spb-hemnet',
  templateUrl: './hemnet.component.html',
  styleUrls: ['./hemnet.component.scss']
})
export class HemnetComponent implements OnInit {

  public utmTags: string = environment.utmTags

  constructor(
    private hemnetService: HemnetService,
    private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      {
        next: (params: ParamMap) => {
          const id = params.get('id')
          if (id) {
            this.hemnetService.getHemnetData(id)
          }
        }
      })
  }
}
