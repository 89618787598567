import {Component, Input} from '@angular/core'

/**
 * A simple backdrop to use when loading data.
 */
@Component({
  selector: 'spb-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent {

  @Input() public text: string
}
